button.btn-primary, a.btn-primary {
    color: $white;

    &:disabled,
    &:hover,
    &:active,
    &:focus-visible {
        color: $white !important;
    }
}
