// Options

$enable-shadows: true !default;

// Colors

$brand-default: #002d2b;
$brand-highlight: #00cbc2;
$brand-highlight-alpha: #00968f;
$brand-highlight-beta: #00fff4;

// fusv-disable
// scss-docs-start gray-colors-map
$white: #fff !default;
$gray-100: #fafafa !default;
$gray-200: #f0f0f0 !default;
$gray-300: #dadada !default;
$gray-400: #b0b3b9 !default;
$gray-500: #6a707a !default;
$gray-600: #555a62 !default;
$gray-700: #404349 !default;
$gray-800: #2a2d31 !default;
$gray-900: #151618 !default;
$black: #000 !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$aqua: #ABEEEB !default;
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #2ed18a !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

$primary: $brand-highlight !default;
$secondary: $brand-default !default;
$success: $green !default;
$info: $gray-300 !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-700 !default;

$body-bg: $gray-100 !default;
$body-color: $gray-700 !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
) !default;

// Prefix for :root CSS variables

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;

// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// Components

$border-radius: 0.5rem !default;

// Typography

$font-family-sans-serif: Roboto, "Helvetica Neue", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji" !default;

$font-size-base: 1rem !default;
$font-size-sm: $font-size-base * .875 !default;

$font-weight-bold:            700 !default;

$line-height-base: 1.5 !default;

// Tables

$table-bg: $white !default;
$table-hover-bg: $gray-100 !default;

// scss-docs-start focus-ring-variables
$focus-ring-width:      .25rem !default;
$focus-ring-opacity:    .25 !default;
$focus-ring-color:      rgba($primary, $focus-ring-opacity) !default;
$focus-ring-blur:       0 !default;
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color !default;

// Buttons + Forms
$input-btn-line-height:       $line-height-base !default;

// Forms
$form-label-margin-bottom:              .25rem !default;

$input-padding-y:                       .375rem !default;
$input-line-height:                     $input-btn-line-height !default;

$input-bg: $white !default;
$input-border-width: var(--#{$prefix}border-width) !default;
$input-border-color: $gray-200 !default;
$input-btn-focus-color:         $focus-ring-color !default;
$input-btn-focus-box-shadow:    $focus-ring-box-shadow !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$form-check-label-color:                  $gray-500 !default;
$form-check-label-cursor:                 pointer !default;

$form-check-inline-margin-end: 1.4rem !default;
$form-floating-label-opacity: 1 !default;
// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-offcanvas-backdrop:         1040 !default;
$zindex-offcanvas:                  1045 !default;
$zindex-modal-backdrop:             1050 !default;
$zindex-modal:                      1055 !default;
$zindex-popover:                    1070 !default;
$zindex-tooltip:                    1080 !default;
$zindex-toast:                      1090 !default;

// Navs

$nav-link-padding-y: .5rem !default;

// Navbar

$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;

// Badges

// scss-docs-start badge-variables
$badge-font-size:                   .875em !default;
$badge-font-weight:                 600 !default;
$badge-color:                       $white !default;
$badge-padding-y:                   .35em !default;
$badge-padding-x:                   .65em !default;
// scss-docs-end badge-variables

// Cards

$card-border-width: 0 !default;
$card-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
$card-bg: $white !default;
$card-cap-bg: rgba($white, 0.3) !default;

// Accordion

$accordion-icon-width: 1.25rem !default;
$accordion-icon-color: $body-color !default;
$accordion-icon-transition: transform .2s ease-in-out !default;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

// Alerts

// between -10 - 10 for color strength.
$alert-bg-level: -10 !default;
$alert-border-level: 1 !default;
$alert-color-level: 3 !default;

// Sidebar

$sidebar-width: 12rem !default;
$sidebar-margin: .5rem !default;