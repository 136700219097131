.list-view table {
    font-size: .875rem;
    border-color: $gray-200;

    thead th {
        border: 1px solid $gray-200;
    }

    th, td {
        color: $gray-700;
    }
}

// Use for the primary column. This does not have a width value so will expand to fill any remaining space
th.col-primary {
    min-width: 15rem;
}

// Use for a name column
th.col-name {
    min-width: 15rem;
    width: 15rem;
}

// Use for a short name column e.g. Product type name
th.col-short-name {
    min-width: 10rem;
    width: 10rem;
}

// Use for a date column and for the checkbox columns for the add remove records component
th.col-date, th.col-records-check, th.col-filter {
    min-width: 12rem;
    width: 12rem;
}

// Use for a code column
th.col-code {
    min-width: 10rem;
    width: 10rem;
}

// Use for a sequence column
th.col-sequence {
    min-width: 8rem;
    width: 8rem;
}

// Use for a select column
th.col-select {
    min-width: 2rem;
    width: 2rem;
}

// Use for numbers and boolean columns
th.col-number, th.col-bool {
    min-width: 10rem;
    width: 10rem;
}
