@import "~bootstrap/scss/functions";

.crmattribute {
	background-color: yellow;
}

.cursor-move {
    cursor: move;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-zoom-in {
    cursor: zoom-in;
}

.currency-input {
    max-width: 7rem;

    .input-group-text { 
        background-color: transparent;
        padding-left: .5rem;
        padding-right: .5rem;
    }

    .form-control {
        padding-left: .5rem;
    }
}

.min-vh-25 {
    min-height: 25vh !important;
}

.min-vh-50 {
    min-height: 50vh !important;
}

.min-vh-75 {
    min-height: 75vh !important;
}

.min-h-100 {
    min-height: 100%;
}

.collapse-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    
    &::before {
        content: escape-svg($accordion-button-icon);
        display: inline-block;
        width: $accordion-icon-width;
        height: $accordion-icon-width;
        margin-right: $accordion-icon-width;
        transform: rotate(-90deg);
        transition: $accordion-icon-transition;
    }

    &.active::before {
        transform: rotate(0deg);
    }
}

.collapse-section {
    background-color: $gray-100;
}